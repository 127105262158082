<template>
    <div>
      <el-row :gutter="20" class="search-box">
        <el-col :span="4">
          <eb-filter-user-list v-model="query.userId" @change="getList(1)"></eb-filter-user-list>
        </el-col>
  
        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </el-col>
      </el-row>
  
      <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="姓名" prop="userRealName"></el-table-column>
      <el-table-column
        label="手机号"
        prop="phone"
        align="right"
      ></el-table-column>

      <el-table-column label="操作金额" align="right" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作后金额" align="right" prop="amount">
        <template #default="scope">
          <span>{{ scope.row.after / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="变更类型" prop="updateDetail">
        <template #default="scope">
          <eb-badge
            :list="this.const.USER_MONEY_DETAIL_TYPE"
            :target="scope.row.updateDetail"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="原因" prop="expandInfo"></el-table-column>

      <el-table-column label="备注" prop="remark"></el-table-column>

      <el-table-column label="创建时间" prop="createTime">
        <template #default="scope">
          <span>{{
            this.$filter.dateFormat(new Date(scope.row.createTime))
          }}</span>
        </template>
      </el-table-column>
    </el-table>
      <el-pagination
        layout="total, prev, pager, next"
        background
        :total="total"
        :page-size="pages.pageSize"
        :current-page="pages.pageIndex"
        @current-change="getList"
      ></el-pagination>
  
    </div>
  </template>
  
  
  <script>
  import {
    fetchMoneyDetail
  } from "@/api/recharge";
  import moment from "moment";
  
  export default {
    data() {
      return {
        dataSource: [],
        modalData: {},
        pages: {
          pageIndex: 1,
          pageSize: 10
        },
        endTime: "",
        expire: -1,
        query: {
          startTime: "",
          endTime: "",
        },
        modalType: "add",
        total: 0,
      };
    },
  
    mounted(){
      this.getList();
    },
  
    methods: {
      changeDate(type) {
        let curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        if (type == 2) {
          this.query.startTime = curDate;
          this.query.endTime = "";
        } else if (type == 1) {
          this.query.endTime = curDate;
          this.query.startTime = "";
        } else {
          this.query.endTime = "";
          this.query.startTime = "";
        }
        this.expire = -1;
        this.getList(1);
      },
 
      getList(current) {
        if (!this.validatenull(current)) {
          this.pages.pageIndex = current;
        }
        fetchMoneyDetail({ ...this.query, ...this.pages }).then((res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          this.dataSource = list;
        });
      },
    },
  };
  </script>
  